import React from "react"
import LayoutThird from "../../components/common/layout-third";
import SEO from "../../components/common/seo";
import SignatureNav from "../../components/common/signature-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/signature/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const FuncionalidadesPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiSignatureFuncionalidades.edges[0].node;
    const customKycElements = (pClassName, bClassName) => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            strong: ({node, ...props}) => <strong className={bClassName} {...props}/>
        }
    }

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-sig.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <SignatureNav pageContext={pageContext}/>

            <section id="product-top" className="content f1 mt-0 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12" data-aos="fade-down">
                            <div align="center">
                                <br/><br/>
                                <h1 className="display-4 font-weight-bold spacing">{page.title}</h1>
                                <h4><span className="badge rounded-pill bg-tecalis mt-3 py-2 pr-3 pl-3 spacing text-white">{page.subtitle}</span></h4>
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        {page.functionality.map((item, i) => {
                            if (i % 2 === 0) {
                                return <>
                                    <div className="col-xl-5 mt-10 mb-5" data-aos="fade-up">
                                        <br/>
                                        <h2 className="display-5 font-weight-black spacing title-feed">
                                            {item.title}
                                        </h2>

                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>
                                    </div>

                                    <div className="col-xl-1"/>

                                    <div className="col-xl-6">
                                        <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                             style={{position: 'absolute', top: '250px', right: '-30px', zIndex: '-99'}}>
                                            <img src={require("../../img/products/ic_decoration.svg").default} alt="Decoration"/>
                                        </div>

                                        <a href={item.video_url} className="desktop--version" data-small-btn="true" target="_blank" data-fancybox="" rel="noopener noreferrer">
                                            <div className="vlt-simple-image1">
                                                <div className="vlt-simple-image__mask1" data-aos="image-mask-animation" data-aos-delay="0">
                                                    <div className="inside1"/>
                                                </div>
                                                <img src={item.image.localFile.publicURL} alt={item.title} className="img-fluid rounded mt-9" data-aos="fade-left"/>
                                            </div>
                                        </a>

                                        <a href={item.video_url} className="mobile--version" target="_blank" data-small-btn="true" data-fancybox="" rel="noopener noreferrer">
                                            <img src={item.image.localFile.publicURL} alt="..." className="img-fluid rounded" data-aos="fade-left"/>
                                        </a>
                                    </div>
                                </>
                            } else {
                                return <>
                                    <div className="col-xl-6">
                                        <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                             style={{position: 'absolute', top: '250px', left: '-30px', zIndex: '-99'}}>
                                            <img src={require("../../img/products/ic_decoration.svg").default} alt="Decoration"/>
                                        </div>

                                        <a data-small-btn="true" href={item.video_url} className="desktop--version" data-fancybox>
                                            <div className="vlt-simple-image1">
                                                <div className="vlt-simple-image__mask1" data-aos="image-mask-animation" data-aos-delay="0">
                                                    <div className="inside1"/>
                                                </div>
                                                <img src={item.image.localFile.publicURL} alt={item.title} className="img-fluid mt-9 rounded" data-aos="fade-left"/>
                                            </div>
                                        </a>

                                        <a href={item.video_url} className="mobile--version" target="_blank">
                                            <img src={item.image.localFile.publicURL} alt={item.title} className="img-fluid rounded" data-aos="fade-left"/>
                                        </a>
                                    </div>

                                    <div className="col-xl-1"/>

                                    <div className="col-xl-5 mt-10 mb-5" data-aos="fade-up">
                                        <br/>

                                        <h2 className="display-5 font-weight-black spacing title-feed">
                                            {item.title}
                                        </h2>

                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>
                                    </div>
                                </>
                            }
                        })}
                    </div>
                    <br/><br/><br/><br/><br/>
                </div>
            </section>

            <section className="f2 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center">
                            <br/><br/><br/>
                            <h2 className="display-4 font-weight-black spacing title-feed">
                                {page.advantage_title}
                            </h2>
                            <br/><br/>

                            <div className="row">
                                {page.advantage_items.map((item, i) => {
                                    return <div className="col-xl-6 mb-9" key={i}>
                                        <div className="row align-items-center mb-3" data-aos="fade-right" data-aos-delay="100">
                                            <div className="col-12 col-lg-3">
                                                <img src={item.icon.localFile.publicURL} alt="..." className="img-fluid" width="110"/>
                                            </div>

                                            <div className="col-12 col-lg-9">
                                                <h2 align="left" className="font-weight-bold mb-2 spacing">
                                                    {item.title}
                                                </h2>

                                                <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing text-left')}/>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>

                            <br/><br/><br/><br/>
                        </div>
                    </div>
                </div>

                <div className="svg-border-angled text-light">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#fff">
                        <polygon points="0,100 100,0 100,100"/>
                    </svg>
                </div>
            </section>

            <section className="f3 mb-12">
                <br/><br/><br/><br/>

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 mb-5">
                            <img src={require("../../img/products/security.svg").default} width="500" alt="..." className="img-fluid"/>
                        </div>

                        <div className="col-xl-7">
                            <ReactMarkdown children={page.data_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-2 spacing', 'default-link')}/>

                            <br/>

                            {page.data_items.map((item, i) => {
                                return <>
                                    <h4 className="font-size-lg text-black-80 mb-2 spacing font-weight-bold">👉 {item.title}</h4>

                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-4 spacing')}/>
                                </>
                            })}
                        </div>
                    </div>

                    <br/>
                </div>
            </section>

            <section className="py-5 links bg-negro-seccion">
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                    <img src={require("../../img/products/ic_decoration_white.svg").default} alt=""/>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <br/><br/>

                            <h2 className="font-weight-bold spacing">
                                {page.customization_title}
                            </h2>

                            <ReactMarkdown children={page.customization_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-white spacing')}/>
                        </div>
                    </div>

                    <div className="links-wrapper">
                        <div className="row links-card">
                            {page.customization_items.map((item, i) => {
                                return <div className="col-xl-4" data-aos="fade-down" data-aos-delay={(i + 1) * 100} key={i}>
                                    <div className="card hvr-float mb-3 pers--card">
                                        <div className="card-body">
                                            <div align="center">
                                                <h5><span className="badge rounded-pill bg-tecalis text-white mt-3 py-2 pr-3 pl-3">{item.title}</span></h5>
                                                <img src={item.icon.localFile.publicURL} className="img-fluid mt-3 mb-3" width="150" alt="..."/>
                                            </div>

                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing mt-5 mb-4 pl-5 pr-5')}/>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                    <br/>
                </div>
            </section>

            <section className="f2 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6" data-aos="fade-up">
                            <br/><br/><br/>

                            <h2 className="display-4 font-weight-bold spacing title-per">
                                {page.tool_title}
                            </h2>

                            <ReactMarkdown children={page.tool_description} rehypePlugins={[rehypeRaw]} components={customKycElements('font-size-lg text-black-800 spacing', 'text-tecalis')} />

                            <br/>

                            <div className="check-list" style={{position: 'relative', left: '-55px'}}>
                                <ul className="spacing mb-5">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            {page.tool_items.slice(0,3).map((item, i) => {
                                                return <li key={i}>{item.description}</li>
                                            })}
                                        </div>
                                        <div className="col-xl-6">
                                            {page.tool_items.slice(3,6).map((item, i) => {
                                                return <li key={i}>{item.description}</li>
                                            })}
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-5" data-aos="fade-down">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: 'absolute', top: '250px', left: '-30px', zIndex: '-99'}}>
                                <img src={require('../../img/products/ic_decoration.svg').default} alt=""/>
                            </div>

                            <div className="vlt-simple-image1">
                                <div className="vlt-simple-image__mask1" data-aos="image-mask-animation" data-aos-delay="0">
                                    <div className="inside1"/>
                                </div>
                                <img src={require("../../img/fun-sig.jpg").default} alt="..." className="img-fluid mb-6 mt-13" data-aos=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f2 bg-grey border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 mb-6 text-center" data-aos="fade-down">
                            <br/><br/>

                            <img src={require("../../img/products/multiplataform.svg").default} width="700" alt="..." className="img-fluid mt-10"/>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-7 mb-8" data-aos="fade-up">
                            <h2 className="display-4 font-weight-bold spacing">
                                <br/><br/>
                                {page.anywhere_title}
                                <br/>
                            </h2>

                            <ReactMarkdown children={page.anywhere_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-2 spacing')} />

                            <br/><br/><br/><br/>
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Signature"/>

            <ModalContactSales pageContext={pageContext} product="Signature"/>

            <ModalWhitePaper pageContext={pageContext}/>

            {/*<ModalSignatureDemo/>*/}
        </LayoutThird>
    )
}

export default FuncionalidadesPage

export const funcionalidadesPageQuery = graphql
    `query($lang: String)
    {
        allStrapiSignatureFuncionalidades ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    subtitle
                    functionality {
                        order
                        title
                        description
                        video_url
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    advantage_title
                    advantage_items {
                        order
                        title
                        icon {
                            localFile {
                                publicURL
                            }                            
                        }
                        description
                    }
                    data_description
                    data_items {
                        order
                        title
                        description
                    }
                    customization_title
                    customization_description
                    customization_items {
                        order
                        title
                        icon {
                            localFile {
                                publicURL
                            }                            
                        }
                        description
                    }
                    tool_title
                    tool_description
                    tool_items {
                        order
                        title
                        description
                    }
                    anywhere_title
                    anywhere_description
                }
            }
        }
        cookies: markdownRemark(
            frontmatter
    :
        {
            lang: {
                eq: $lang
            }
            name: {
                eq: "cookies"
            }
        }
    )
        {
            frontmatter
            {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;
